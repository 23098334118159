import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  ListItem,
  Stack,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ChatMessage from './ChatMessage';
import { useGroupsContext } from 'contexts/GroupsContext';
import { useListMessagesQuery } from 'services/messages';
import InfiniteScrollReverse from 'components/InfiniteScrollReverse';
import { useSearchParams } from 'react-router-dom';
import { webSocketType } from 'contexts/WebSocketContext';

function GroupChatMessages() {
  const { selectedGroup, lastMessage, setLastMessage } = useGroupsContext();
  console.log(
    'file: GroupChatMessages.js:19 ~ GroupChatMessages ~ lastMessage:',
    lastMessage
  );
  const [listCursor, setListCursor] = useState('');
  const {
    data: response,
    isFetching,
    isLoading
  } = useListMessagesQuery({
    groupId: selectedGroup._id,
    limit: 2,
    cursor: listCursor
  });

  const [messages, setMessages] = useState([]);

  const listRef = useRef(null);
  const commentItemRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const messageId = searchParams.get('message');

  useEffect(() => {
    if (!isFetching && response) {
      const listMessages =
        response?.map((el) => {
          const sentBy = selectedGroup.members.find(
            (member) => member._id === el.sentBy || member.id === el.sentBy
          );
          return {
            ...el,
            sentBy: {
              ...sentBy,
              _id: sentBy._id || sentBy.id
            }
          };
        }) || [];
      setMessages((prevState) => {
        if (!listCursor) return listMessages || [];
        return [...(listMessages || []), ...prevState];
      });
      if (!listCursor) {
        setTimeout(() => {
          listRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 50);
      }
    }
  }, [listCursor, isFetching, response, listRef, selectedGroup.members]);

  useEffect(() => {
    if (!isFetching && commentItemRef && messageId) {
      const timeout = setTimeout(() => {
        commentItemRef.current?.scrollIntoView({ behavior: 'smooth' });
        searchParams.delete('message');
        setSearchParams(searchParams.toString());
      }, 55);
      return () => clearTimeout(timeout);
    }
  }, [searchParams, commentItemRef, messageId, isFetching, setSearchParams]);

  useEffect(() => {
    if (
      lastMessage &&
      lastMessage.data &&
      selectedGroup &&
      lastMessage.data.groupId === selectedGroup._id
    ) {
      const sentBy = selectedGroup.members.find(
        (member) => member._id === lastMessage.data.sentBy
      );
      const message = {
        ...lastMessage.data,
        group: lastMessage.data?.groupId || lastMessage.data?.group?.id,
        sentBy: {
          ...sentBy,
          _id: sentBy._id || sentBy.id
        }
      };
      switch (lastMessage.type) {
        case webSocketType.CREATE_MESSAGE:
          setMessages((prevState) => [...prevState, message]);
          setTimeout(() => {
            listRef.current?.scrollIntoView({ behavior: 'smooth' });
          }, 100);
          break;
        case webSocketType.UPDATE_MESSAGE:
          setMessages((prevState) => {
            return prevState.map((item) =>
              item._id === message._id ? message : item
            );
          });
          break;
        case webSocketType.DELETE_MESSAGE:
          setMessages((prevState) =>
            prevState.filter((item) => item._id !== message._id)
          );
          break;
        default:
      }
      setLastMessage(null);
    }
  }, [lastMessage, selectedGroup, setLastMessage]);

  if (isLoading) {
    return (
      <Stack
        height="calc(100vh - 170px - 56px)"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Stack>
    );
  }

  const handleLoadMore = () => {
    if (!isFetching && response?.next) {
      setListCursor(response?.next);
    }
  };

  return (
    <Stack height="calc(100vh - 170px - 56px)">
      <InfiniteScrollReverse
        isLoading={isLoading}
        hasMore={!!response?.next}
        loadMore={handleLoadMore}
        loadArea={30}
      >
        {isFetching && listCursor && (
          <Stack alignItems="center">
            <CircularProgress size="small" />
          </Stack>
        )}
        {!isFetching && !response?.next && (
          <ListItem>
            <Stack flex={1} alignItems="center">
              <Typography variant="body2" color={grey[500]} p={1}>
                Group Created.
              </Typography>
            </Stack>
          </ListItem>
        )}
        {messages.map((item) => (
          <ListItem key={item._id}>
            {item._id === messageId && (
              <Box
                ref={commentItemRef}
                id={`ref-msg-${messageId}`}
                sx={{ position: 'relative', top: -250 }}
              />
            )}
            <ChatMessage message={item} />
          </ListItem>
        ))}
        <div ref={listRef} />
      </InfiniteScrollReverse>
    </Stack>
  );
}

export default GroupChatMessages;
