import {
  useMemo,
  useReducer,
  useState,
  useEffect,
  useRef,
  Fragment
} from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {
  Typography,
  Divider,
  Grid,
  useTheme,
  useMediaQuery,
  TextField,
  IconButton,
  Checkbox,
  Autocomplete
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  pushFolersAndFiles,
  selectCurrentFolderView
} from '../../../stores/slidebox/slideboxSlice';
import Alert from '../../../components/Alert';
import { useGetTagsQuery } from 'services/tags';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import axios from '../../../services/axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LinearProgressWithLabel from '../../../components/LinearProgressWithLabel';
import { getSize } from '../../../utils/fileUtils';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Delete } from '@mui/icons-material';
import { FILE_TYPES } from '../../../constants';
import { useSlideBox } from 'hooks/slideBox/useSlideBox';
import { selectOrganization } from '../../../stores/auth/authSlice';
import { useGetProjectFolderChildrenByIdMutation } from 'services/projects';
import { useSlideboxContext } from 'hooks/slideBox/useSlideBoxContext';
import { RenameSlideWarningDialog } from '../FileExplorer/components/ActionMenuComponents/RenameSlideWarningDialog';

const initialState = {
  files: [],
  uploadProcess: [] // {percentages: 0, isSuccess: false, isError: false, isUploading: false, errorMessage: '' }
};

const increasePercentage = (input) => {
  if (input >= 98) return input;
  if (input >= 90) return input + 0.0001;
  if (input >= 80) return input + 0.001;
  if (input >= 60) return input + 0.01;
  return input + 0.1;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILES': {
      return {
        ...state,
        files: action.payload
      };
    }
    case 'SET_UPLOAD_PROCESS': {
      return {
        ...state,
        uploadProcess: action.payload
      };
    }
    case 'FINISH_UP_PROCESS_BY_INDEX': {
      const newProcess = [...state.uploadProcess];
      clearInterval(newProcess[action.payload.index].interval);
      newProcess[action.payload.index] = {
        isSuccess: true,
        isError: false,
        isUploading: false,
        errorMessage: '',
        percentages: 100,
        interval: null
      };
      return {
        ...state,
        uploadProcess: newProcess
      };
    }
    case 'CATCH_PROCESS_ERROR_BY_INDEX': {
      const newProcess = [...state.uploadProcess];
      clearInterval(newProcess[action.payload.index].interval);
      newProcess[action.payload.index] = {
        isSuccess: false,
        isError: true,
        isUploading: false,
        errorMessage: action.payload.errorMessage || 'File Uploading failed !',
        percentages: 100,
        interval: null
      };
      return {
        ...state,
        uploadProcess: newProcess
      };
    }
    case 'INCREASE_PROCESS_PERCENTAGE_BY_INDEX': {
      const newProcess = [...state.uploadProcess];
      const process = newProcess[action.payload.index];
      if (process.percentages >= 100) {
        clearInterval(process.interval);
        return state;
      }
      let value = increasePercentage(process.percentages);
      if (action.payload.value) {
        value = action.payload.value;
      } else if (action.payload.increaseBy) {
        value = process.percentages + action.payload.increaseBy;
      }
      newProcess[action.payload.index].percentages = value;

      return {
        ...state,
        uploadProcess: newProcess
      };
    }
    default: {
      return state;
    }
  }
};

const UploadFiles = () => {
  const inputAccept =
    '.png, .jpg, .jpeg, .webp, .avif, .tiff, .tif, .gif, .svg, .bmp, .svs, .ndpi';
  const inputFileRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [openUploadingModal, setOpenUploadingModal] = useState(false);
  const [slides, setSlides] = useState([]);
  const organization = useSelector(selectOrganization);
  const [currentSlideNames, setCurrentSlideNames] = useState([]);
  const theme = useTheme();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [openRenamingModal, setOpenRenamingModal] = useState(false);

  const isPortable = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const storeDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setViewComponent } = useSlideboxContext();

  const [fetchedTags, setTags] = useState([]);

  const isAnyMissingSlideName = useMemo(
    () => slides.length && slides.some((slide) => !slide.slideName),
    [slides]
  );

  const [projectName] = useState('');

  const {
    _id: folderId,
    name: folderName,
    type: folderType,
    caseIdentifiers,
    createdByEmail
  } = useSelector(selectCurrentFolderView);

  const [getProjectFolderChildrenById] =
    useGetProjectFolderChildrenByIdMutation();

  const { refetch: getTags } = useGetTagsQuery(
    {
      entityModel: 'project',
      entityId: folderId || ''
    },
    {
      refetchOnMountOrArgChange: false
    }
  );

  const { handleSetCurrentView } = useSlideBox();

  const [currentFolderId] = useState(folderId);
  const [allTags, setAllTags] = useState([]);
  const [allCaseIdentifiers, setAllCaseIdentifiers] = useState([]);
  useEffect(() => {
    setSlides((currentSlides) => {
      if (!state.files.length) {
        return [];
      }
      const newSlides = state.files.map((file) => {
        const found = currentSlides.find(
          (slide) => slide.file.name === file.name
        );
        if (found) {
          return found;
        }
        return {
          file: file,
          slideName: file.name
        };
      });
      return newSlides;
    });
  }, [state.files]);

  useEffect(() => {
    if (
      folderId &&
      folderType === FILE_TYPES.CASE &&
      currentFolderId === folderId
    ) {
      const entityId = folderId;
      const entityModel = 'project';
      getTags({ entityModel, entityId })
        .unwrap()
        .then((data) => {
          setTags(
            data
              ? data.map((item) => ({
                  _id: item._id,
                  name: item.name
                }))
              : []
          );
        })
        .catch((error) => {
          console.error('Error fetching tag:', error);
        });

      const getChildren = async (id) => {
        try {
          const data = await getProjectFolderChildrenById(id).unwrap();
          const names = data
            .filter((item) => item.type === FILE_TYPES.FILE)
            .map((item) => item.name);
          setCurrentSlideNames(names);
        } catch (err) {
          setCurrentSlideNames([]);
        }
      };
      getChildren(entityId);
    } else if (currentFolderId !== folderId) {
      if (unsavedChanges) {
        setConfirmDialogOpen(true);
      } else {
        setViewComponent('file_explorer');
      }
    } else {
      setViewComponent('file_explorer');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const cancelDialog = () => {
    setConfirmDialogOpen(false);
    handleSetCurrentView(currentFolderId);
  };
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true);
    },
    onMouseLeave: () => {
      setIsMouseOver(false);
    },
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      handleFileChange(e.dataTransfer.files);
    }
  };
  const handleFileChange = (fileList) => {
    const files = [...Array(fileList.length).keys()].map((i) => fileList[i]);
    dispatch({ type: 'SET_FILES', payload: files });
    inputFileRef.current.value = null;
    setUnsavedChanges(true);
  };

  const handleChooseFile = () => {
    inputFileRef.current.click();
  };

  const handleCloseUploadingModal = () => {
    setOpenUploadingModal(false);
    dispatch({
      type: 'SET_UPLOAD_PROCESS',
      payload: []
    });
    dispatch({
      type: 'SET_FILES',
      payload: []
    });
    setViewComponent('file_explorer');
  };

  const handleCloseRenamingModal = () => {
    setOpenRenamingModal(false);
  }

  const handleSlideNameChange = (event, index) => {
    const value = event.target.value;
    const trimmedValue = value.trim();
    setSlides((slides) => {
      if (trimmedValue === '') {
        slides[index].slideName = null;
      } else {
        slides[index].slideName = value;
      }
      setUnsavedChanges(true);
      return [...slides];
    });
  };

  const deleteSlide = (index) => {
    const files = [...state.files];
    files.splice(index, 1);
    dispatch({
      type: 'SET_FILES',
      payload: files
    });
  };
  const handleNavigateAway = () => {
    if (unsavedChanges) {
      setConfirmDialogOpen(true);
    } else {
      setViewComponent('file_explorer');
    }
  };

  const handleUploadFile = async () => {
    const formNames = slides.map((slide) => slide.slideName);
    const slideNames = [...formNames, ...currentSlideNames];

    const checkDuplicateSlideName = slideNames.find(
      (slideName, index) => slideNames.indexOf(slideName) !== index
    );

    if (checkDuplicateSlideName) {
      setOpenRenamingModal(true);
      return;
    }

    setOpenUploadingModal(true);
    dispatch({
      type: 'SET_UPLOAD_PROCESS',
      payload: slides.map((slide, index) => ({
        percentages: 0,
        isSuccess: false,
        isError: false,
        isUploading: true,
        errorMessage: '',
        interval: setInterval(() => {
          dispatch({
            type: 'INCREASE_PROCESS_PERCENTAGE_BY_INDEX',
            payload: {
              index
            }
          });
        }, 1)
      }))
    });

    slides.map((slide, index) => {
      const slideCaseIdentifiers = allCaseIdentifiers[index]
        ? allCaseIdentifiers[index].map(
            (caseIdentifier) => caseIdentifier._id || caseIdentifier
          )
        : [];
      const tags = allTags[index]
        ? allTags[index].map((tag) => tag.id || tag)
        : [];
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', slide.file);
        formData.append('slideName', slide.slideName);
        formData.append('createdByEmail', createdByEmail);
        if (projectName) {
          formData.append('projectName', projectName);
          formData.append('folderName', folderName);
        } else {
          formData.append('projectName', folderName);
        }
        formData.append('tags', JSON.stringify(tags));
        formData.append(
          'caseIdentifiers',
          JSON.stringify(slideCaseIdentifiers)
        );
        const headers = {
          'Content-Type': 'multipart/form-data' // A default header
        };

        if (organization) {
          headers['organization'] = organization._id; // Add the Authorization header conditionally
        }
        axios
          .post('/slides/uploadSlideToProject', formData, {
            headers: headers
          })
          .then(({ data }) => {
            if (data && data.data) {
              if (folderType === FILE_TYPES.FOLDER) {
                storeDispatch(
                  pushFolersAndFiles([
                    {
                      ...data.data.project,
                      parent: folderId,
                      type: FILE_TYPES.CASE
                    }
                  ])
                );
              } else {
                storeDispatch(
                  pushFolersAndFiles([
                    {
                      ...data.data.slide,
                      parent: folderId,
                      tags: allTags[index] || [],
                      caseIdentifiers: allCaseIdentifiers[index] || [],
                      parentCaseIdentifiers: caseIdentifiers || [],
                      type: FILE_TYPES.FILE
                    }
                  ])
                );
              }
              dispatch({
                type: 'FINISH_UP_PROCESS_BY_INDEX',
                payload: {
                  index
                }
              });
              resolve(true);
            }
          })
          .catch((error) => {
            let message =
              typeof error?.response?.data?.error === 'string'
                ? error.response.data.error
                : '';
            if (!message) message = 'Something went wrong !';
            dispatch({
              type: 'CATCH_PROCESS_ERROR_BY_INDEX',
              payload: {
                index,
                errorMessage: message
              }
            });
            enqueueSnackbar(message, {
              variant: 'error'
            });
            reject(error);
          });
      });
    });

    setUnsavedChanges(false);
  };

  // get duplicate slideName name
  const duplicateSlideName = useMemo(() => {
    const formNames = slides.map((slide) => slide.slideName);
    const slideNames = [...formNames, ...currentSlideNames];

    const checkDuplicateSlideName = slideNames.find(
      (slideName, index) => slideNames.indexOf(slideName) !== index
    );
    return checkDuplicateSlideName;
  }, [slides, currentSlideNames]);

  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ height: '100%' }}>
      <h2>
        {folderName
          ? `Upload to ${
              folderType === FILE_TYPES.FOLDER ? 'folder' : 'project'
            }: ${folderName}`
          : 'Select a folder or project'}{' '}
      </h2>
      <h6>Please upload your slides</h6>
      <Box
        flexDirection={isPortable ? 'column-reverse' : 'row'}
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
          flexGrow: 1,
          overflowY: 'auto',
          position: 'relative'
        }}
      >
        <Box
          mb={2}
          sx={{
            position: 'sticky',
            top: '0',
            backgroundColor: '#FFFFFF',
            zIndex: 2
          }}
        >
          <input
            id="file-upload"
            type="file"
            accept={inputAccept}
            style={{ display: 'none' }}
            ref={inputFileRef}
            onChange={(e) => handleFileChange(e.target.files)}
            // multiple
          />
          <label htmlFor="file-upload" {...dragEvents}>
            <Box
              sx={{
                backgroundColor: '#F6F6F6',
                borderRadius: '5px',
                padding: '7px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '50px 8px',
                  border: '2px dashed #B9B9B9',
                  borderRadius: '5px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <UploadFileIcon sx={{ fontSize: '41px' }} />
                  <Typography
                    sx={{
                      color: '#4A4A4A',
                      fontWeight: '600',
                      fontSize: '12px',
                      paddingLeft: '7px'
                    }}
                  >
                    Drag & drop files here
                  </Typography>
                </Box>

                <Button
                  variant="contained"
                  color="gray"
                  disableElevation
                  htmlFor="file-upload"
                  onClick={handleChooseFile}
                  sx={{
                    fontSize: '12px',
                    paddingLeft: '20px',
                    paddingRight: '20px'
                  }}
                >
                  CHOOSE FILE
                </Button>
              </Box>
            </Box>
          </label>
        </Box>
        <List
          sx={{
            minHeight: 'calc(100% - 99px - 159px)',
            [theme.breakpoints.down('md')]: {
              minHeight: 'calc(100% - 99px - 115px)'
            }
          }}
        >
          {slides.map((slide, index) => (
            <Fragment key={slide.file.name}>
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => deleteSlide(index)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <Box minWidth={0} sx={{ width: '100%' }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      display="flex"
                      alignItems="center"
                    >
                      File:
                      <Typography
                        sx={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          color: '#4A4A4A',
                          fontWeight: '600',
                          fontSize: '12px',
                          paddingLeft: '7px'
                        }}
                      >
                        {slide.file.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        required
                        error={
                          !slide.slideName ||
                          slide.slideName.trim() === ''
                        }
                        sx={{
                          '& label.MuiInputLabel-shrink': {
                            color: '#4A4A4A',
                            fontWeight: '600'
                          }
                        }}
                        size="small"
                        label="Slide Name"
                        fullWidth
                        onChange={(event) =>
                          handleSlideNameChange(event, index)
                        }
                        value={slide.slideName}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              <ListItem>
                <Box minWidth={0} sx={{ width: '100%', paddingRight: 4 }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      display="flex"
                      alignItems="center"
                    />
                    <Grid item xs={12} md={5}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="tags-outlined"
                        size="small"
                        options={fetchedTags}
                        onChange={(event, newValue) => {
                          if (Array.isArray(newValue)) {
                            const updatedArrayValue = newValue.filter((e) =>
                              typeof e === 'string' ? e.trim() : e
                            );
                            const newArrayValue = [...updatedArrayValue];
                            const newTags = [...allTags];
                            newTags[index] = newArrayValue;
                            setAllTags(newTags);
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.name === value.name
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.name;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option.name}</li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              '& label.MuiInputLabel-shrink': {
                                color: '#4A4A4A',
                                fontWeight: '600'
                              }
                            }}
                            label="Slide Tags"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              <ListItem>
                <Box minWidth={0} sx={{ width: '100%', paddingRight: 4 }}>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={7}
                      display="flex"
                      alignItems="center"
                    />
                    <Grid item xs={12} md={5}>
                      <Autocomplete
                        multiple
                        id="case-outlined"
                        size="small"
                        disableCloseOnSelect
                        options={caseIdentifiers || []}
                        onChange={(event, value) => {
                          if (Array.isArray(value)) {
                            const updatedArrayValue = value.filter((e) =>
                              typeof e === 'string' ? e.trim() : e
                            );
                            const newArrayValue = [...updatedArrayValue];
                            const newCaseIdentifiers = [...allCaseIdentifiers];
                            newCaseIdentifiers[index] = newArrayValue;
                            setAllCaseIdentifiers(newCaseIdentifiers);
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.patientId === value.patientId
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === 'string') {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return `Patient Id: ${option.patientId}`;
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              checked={selected}
                            />
                            Patient Id: {option.patientId}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              '& label.MuiInputLabel-shrink': {
                                color: '#4A4A4A',
                                fontWeight: '600'
                              }
                            }}
                            label="Case Identifiers"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </ListItem>
              <Divider sx={{ my: 2 }} />
            </Fragment>
          ))}
        </List>
        <Box
          sx={{
            position: 'sticky',
            bottom: '0',
            backgroundColor: '#FFFFFF',
            zIndex: 2
          }}
        >
          <Box
            sx={{
              backgroundColor: '#F6F6F6',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: isPortable ? 'column' : 'row',
              padding: '12px'
            }}
          >
            <Box
              mt={isPortable ? 2 : 0}
              sx={{
                ...(isPortable ? { width: '100%', display: 'flex' } : {})
              }}
            >
              <Button
                variant="contained"
                fullWidth={isPortable}
                color="gray"
                disableElevation
                sx={{
                  fontSize: '12px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  mr: 1
                }}
                onClick={handleNavigateAway}
              >
                BACK
              </Button>
              <LoadingButton
                fullWidth={isPortable}
                variant="contained"
                component="label"
                disableElevation
                sx={{
                  fontSize: '12px',
                  paddingLeft: '20px',
                  paddingRight: '20px'
                }}
                disabled={
                  !slides.length ||
                  isAnyMissingSlideName ||
                  (!projectName && folderType === FILE_TYPES.FOLDER)
                }
                onClick={handleUploadFile}
              >
                UPLOAD
              </LoadingButton>
            </Box>
            <Alert
              title="Unsaved Changes"
              content="You have unsaved changes. Do you want to discard them and navigate away?"
              open={isConfirmDialogOpen}
              onDismiss={cancelDialog}
              onConfirm={() => {
                setConfirmDialogOpen(false);
                setViewComponent('file_explorer');
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* <Grid item xs={5}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InsertDriveFileIcon />
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
              SUPPORTED FILE FORMATS
            </Typography>
          </Box>

          <List
            sx={{
              listStyleType: 'disc',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '12px',
              letterSpacing: '0.02em',
              color: '#494949'
            }}
          >
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              Non-proprietary (JPG, PNG)
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              Aperio (SVG, TIF)
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              Leica (SCN)
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              Hamamatus (NDPI, VMS)
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              3D Histech
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              Ventana (BIF)
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginBottom: '8px',
                marginLeft: '16px'
              }}
            >
              Philips (TIFF)
            </ListItem>
            <ListItem
              style={{
                display: 'list-item',
                padding: '0px',
                marginLeft: '16px'
              }}
            >
              MRXS
            </ListItem>
          </List>
        </Grid> */}
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={openUploadingModal}
      >
        <DialogTitle color="primary">Upload Slides...</DialogTitle>
        <DialogContent dividers>
          {state.uploadProcess.length &&
            state.uploadProcess.map((process, index) => (
              <div key={index} style={{ marginBottom: '8px' }}>
                <div
                  style={{
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {process.isError && (
                    <ReportProblemIcon
                      fontSize="18px"
                      color="error"
                      style={{ marginRight: '4px' }}
                    />
                  )}{' '}
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '600'
                      }}
                    >
                      {slides[index].slideName}:{' '}
                    </span>
                    {getSize(slides[index].file.size)}
                  </div>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%'
                    }}
                  >
                    {process.isError && (
                      <span
                        style={{
                          color: '#ff1744',
                          marginLeft: '4px'
                        }}
                        title={process.errorMessage}
                      >
                        {process.errorMessage}
                      </span>
                    )}
                  </div>
                </div>
                {(process.isUploading || process.isSuccess) && (
                  <LinearProgressWithLabel
                    variant="determinate"
                    value={process.percentages}
                  />
                )}
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          {state.uploadProcess.every((process) => !process.isUploading) && (
            <Button onClick={handleCloseUploadingModal}>Upload Done</Button>
          )}
        </DialogActions>
      </Dialog>
      <RenameSlideWarningDialog open={openRenamingModal} onClose={handleCloseRenamingModal} />
    </Box>
  );
};

export default UploadFiles;
