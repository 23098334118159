import moment from 'moment';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useForm, useWatch } from 'react-hook-form';
import {
  useDeleteAnnotationMutation,
  useEditAnnotationMutation
} from 'services/annotation';
import { useSnackbar } from 'notistack';
import { MuiColorInput } from 'mui-color-input';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  TextareaAutosize,
  Typography,
  useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import { useGetFileByIdMutation } from 'services/slides';
import AnnotationItemInfoRows from './AnnotationItemInfoRows';
import { CustomInput } from 'components/Shared/Input';
import Alert from 'components/Alert';
import useAnnotationSocket from 'hooks/useAnnotationSocket';
import AnnotationItemHead from './AnnotationComment/AnnotationItemHead';
import { addStyleToAnnotationAndPointer } from '../../../../utils/shape.helper';

const AnnotationItem = ({ annotation, onUpdated }) => {
  const [state, dispatch] = useContext(SlideViewerContext);
  const { emitAnnotationDeleted } = useAnnotationSocket(
    annotation.slide,
    dispatch,
    state.anno
  );
  const [editMode, setEditMode] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, reset, setValue, control } = useForm();
  const currentTitle = useWatch({
    name: 'title',
    control
  });
  const currentColor = useWatch({
    name: 'stroke',
    control
  });
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [editAnnotation] = useEditAnnotationMutation();
  const [getFileById] = useGetFileByIdMutation();
  const [deleteAnnotation] = useDeleteAnnotationMutation();

  const rows = [
    {
      label: 'Annotator',
      value: annotation?.createdBy
        ? `${annotation.createdBy.firstName} ${annotation.createdBy.lastName}`.trim()
        : ''
    },
    {
      label: 'Type',
      value: annotation.shapeType
    },
    {
      label: 'Created At',
      value: moment(annotation.createdAt).format('MMM DD, yyyy')
    }
  ];

  useEffect(() => {
    if (annotation) {
      reset({
        description: annotation.description,
        stroke: annotation.stroke,
        title: annotation.title
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotation]);

  const selectAnnotation = () => {
    if (state.anno && state.selectedAnno.id !== annotation.id) {
      state.anno.panTo(annotation.id);
      state.anno.selectAnnotation(annotation);
      dispatch({
        type: 'UPDATE_SELECTED_ANNO',
        key: 'selectedAnno',
        value: annotation
      });
      sessionStorage.setItem('currentAnnoTitle', annotation.title);
      const element = document.querySelector(
        '.a9s-annotation.editable.selected'
      );
      const childElements = element.children;
      if (childElements && childElements.length > 0) {
        addStyleToAnnotationAndPointer(childElements, annotation);
      }
    }
  };
  const enableEditMode = () => {
    setEditMode(true);
  };
  const cancelEdit = () => {
    setEditMode(false);
    reset({
      description: annotation.description,
      stroke: annotation.stroke,
      title: annotation.title
    });
  };

  const updateGroup = async (id) => {
    const { data } = await getFileById(id).unwrap();
    dispatch({
      type: 'UPDATE_GROUP',
      payload: data.groups
    });
  };

  const save = async (form) => {
    setLoading(true);
    try {
      let editData = {
        ...annotation,
        ...form
      };
      delete editAnnotation.slide;
      await editAnnotation({
        ...editData,
        id: annotation._id
      }).unwrap();
      // onUpdated?.({
      //   ...editData,
      //   id: annotation._id,
      //   _refreshGroup: annotation.stroke !== form.stroke
      // });

      dispatch({
        type: 'UPDATE_ANNOTATION',
        payload: {
          ...editData,
          id: annotation._id,
          _refreshGroup: annotation.stroke !== form.stroke
        }
      });

      if (annotation.stroke !== form.stroke) {
        updateGroup(annotation?.slide?._id || annotation.slide);
        dispatch({
          type: 'UPDATE_GROUP_CHANGE',
          key: 'selectedGroupChange',
          value: 1
        });
      }

      enqueueSnackbar('Update annotation', {
        variant: 'success'
      });
      setEditMode(false);
    } catch (err) {
      console.log(err);
      enqueueSnackbar('Update annotation failed', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleColorChange = (color) => {
    setValue('stroke', color);
  };

  const handleTitleChange = (title) => {
    setValue('title', title);
  };

  const handleDeleteAnnotation = async () => {
    if (!annotation) return;
    setDeleteAlert(false);
    try {
      await deleteAnnotation(annotation._id);
      enqueueSnackbar('Deleted annotation successfully', {
        variant: 'success'
      });
      dispatch({
        type: 'DELETE_ANNOTATION',
        payload: annotation._id
      });
      await updateGroup(annotation?.slide?._id || annotation.slide);
      emitAnnotationDeleted({ slide: annotation.slide, _id: annotation._id });
    } catch (e) {
      console.error(e);
      enqueueSnackbar(e.toString(), {
        variant: 'error'
      });
    }
  };

  return (
    <Box>
      <AnnotationItemHead
        id={annotation._id}
        stroke={annotation.stroke}
        editMode={editMode}
        onEdit={enableEditMode}
        onDelete={() => setDeleteAlert(true)}
      >
        {editMode ? (
          <CustomInput
            fullWidth
            value={currentTitle}
            onChange={handleTitleChange}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyPress={(e) => e.stopPropagation()}
            inputProps={{
              className: 'custom-input'
            }}
            {...register('title')}
          />
        ) : (
          <h4 className="annotation-title">{annotation.title}</h4>
        )}
        <IconButton color="primary" onClick={selectAnnotation}>
          <InfoOutlinedIcon sx={{ fontSize: '15px' }} />
        </IconButton>{' '}
      </AnnotationItemHead>
      <AnnotationItemInfoRows rows={rows} />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          Tags:
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            fontWeight: '500'
          }}
        >
          {annotation.tags &&
            annotation.tags.length > 0 &&
            annotation.tags.map((tag) => {
              const tagObj = state.slide?.tags?.find(
                (t) => t.id === tag.id
              );
              if (!tagObj) return null;
              return (
                <Chip
                  sx={{ margin: '0px 4px 4px' }}
                  key={tagObj.id}
                  label={tagObj.name}
                />
              );
            })}
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          Case Identifiers:
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            fontWeight: '500'
          }}
        >
          {annotation.caseIdentifiers &&
            annotation.caseIdentifiers.length > 0 &&
            annotation.caseIdentifiers.map((caseIdentifier) => {
              const caseIdentifierObj =
                state.slide?.caseIdentifiers?.find(
                  (ci) => ci.id === caseIdentifier.id
                );
              if (!caseIdentifierObj) return null;
              return (
                <Chip
                  sx={{ margin: '0px 4px 4px' }}
                  key={caseIdentifierObj.id}
                  label={`Patient Id: ${caseIdentifierObj.patientId}`}
                />
              );
            })}
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          Color:
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: '500',
            color: currentColor
          }}
        >
          <MuiColorInput
            className="custom-color-picker-input"
            sx={{ margin: '0px 4px 4px', padding: '0px 0px 7px' }}
            value={currentColor}
            onChange={handleColorChange}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
            format="hex8"
            disablePopover={!editMode}
            disabled={!editMode}
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={12}
          sx={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}
        >
          <Typography>Description:</Typography>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="..."
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
            style={{
              background: theme.palette.buttonLightGray.main,
              borderRadius: '8px',
              width: '100%',
              border: 'none',
              padding: '8px'
            }}
            {...register('description')}
            readOnly={!editMode}
          />
        </Grid>
      </Grid>
      {editMode && (
        <Grid container spacing={2} sx={{ marginTop: '2px' }}>
          <Grid item xs={6}>
            <LoadingButton
              variant="outlined"
              fullWidth
              size="small"
              loading={loading}
              onClick={cancelEdit}
            >
              Cancel
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="small"
              loading={loading}
              onClick={handleSubmit(save)}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      <Box mt={3}>
        <Divider />
      </Box>
      <Alert
        open={deleteAlert}
        title="Delete annotation"
        content="Are you sure to delete this annotation?"
        onDismiss={() => setDeleteAlert(false)}
        onConfirm={handleDeleteAnnotation}
      />
    </Box>
  );
};
export default AnnotationItem;
