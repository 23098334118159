import React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { uuidv4 } from '@firebase/util';
import { Container, TableCell, TableRow, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import CustomTable from '../../../components/Table';

const AnalysisPopUp = ({ biomarkers, isFetching }) => {
  const theme = useTheme();

  let headCells = [];
  if (typeof biomarkers != 'undefined') {
    let internalHeadCell = [];
    const keys = Object.keys(biomarkers.biomarkerData[0]);
    keys.forEach((key) => {
      const headCell = {
        id: key,
        label: key,
        sortable: true
      };
      internalHeadCell.push(headCell);
    });
    headCells.push(internalHeadCell);
  }
  return (
    <Container maxWidth="100%">
      {typeof biomarkers != 'undefined' ? (
        <TabContext value={'0'}>
          <TabPanel value="0" key="0">
            <CustomTable
              rows={biomarkers.biomarkerData}
              headCells={headCells[0]}
              isLoading={isFetching}
              pagingMarginRight="48px"
            >
              {(head, items) =>
                items.map((biomarker) => (
                  <TableRow hover={true} key={uuidv4()}>
                    {Object.keys(biomarker).map((attr) => (
                      <TableCell align="left" key={uuidv4()}>
                        <Box
                          sx={{
                            textAlign: 'left',
                            [theme.breakpoints.down('sm')]: {
                              maxWidth: '60px'
                            }
                          }}
                        >
                          {biomarker[attr]}
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              }
            </CustomTable>
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{ textAlign: 'center', color: theme.palette.text.textGray1 }}
          mt={1}
        >
          {isFetching ? 'Loading analysis data..' : 'No analysis data found'}
        </Box>
      )}
    </Container>
  );
};

export default AnalysisPopUp;
