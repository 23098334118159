import { useEffect, useReducer, useRef, useState, createContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ZoomIn from '@mui/icons-material/ZoomIn';
import ZoomOut from '@mui/icons-material/ZoomOut';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import UploadIcon from '@mui/icons-material/Upload';

import OpenSeaDragon from 'openseadragon';
import * as Annotorious from '@recogito/annotorious-openseadragon';
import '@recogito/annotorious-openseadragon/dist/annotorious.min.css';

import axios from '../../../services/axios';

import ToolButton from 'components/ToolButton';
import DrawToolbar from 'components/DrawToolbar';
import DescSec from 'components/DescSec';

import {
  useCreateAnnotationMutation,
  useDeleteAnnotationMutation,
  useEditAnnotationMutation
} from 'services/annotation';
import { useSnackbar } from 'notistack';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Autocomplete,
  TextField
} from '@mui/material';
import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import { MuiColorInput } from 'mui-color-input';
import {
  useExportAnnotationsMutation,
  useGetFileByIdMutation
} from 'services/slides';
import { SHAPE_TYPES } from 'constants';
import SlideManager from '../SlideManager/SlideManager';
import { SlideViewerContext } from 'hooks/useSlideViewerContext';
import moment from 'moment';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import RubberbandFreehandTool from 'components/DrawTool/Freehand/RubberbandFreehandTool';
import RubberbandEllipseTool from 'components/DrawTool/Ellipse/RubberbandEllipseTool';
// import InviteButton from './InviteButton';
import AnalysisPopUp from './AnalysisPopUp';
import AnalysisGraphPopUp from './AnalysisGraphPopUp';
import AnalysisGraphPopUpWithCompare from './AnalysisGraphPopUpWithCompare';
import CopyButton from 'features/Slidebox/FileExplorer/components/CopyButton';
import MoveButton from 'features/Slidebox/FileExplorer/components/MoveButton';
import ShareButton from 'features/Slidebox/FileExplorer/components/ShareButton';
import { useAuth } from 'hooks/useAuth';
import {
  AnalysisGraphBtnWidget,
  AnalysisGraphCompBtnWidget,
  AnnoAnalysisBtnWidget,
  AnnoFromWidget,
  AnnoInputWidget,
  AnnoAutocompleteWidget
} from 'components/Widgets';
import { selectOrganization } from '../../../stores/auth/authSlice';
import useToggleState from 'hooks/useToggleState';
import Alert from 'components/Alert';
import InfoAlert from 'components/Alert/InfoAlert';
import useAnnotationSocket from 'hooks/useAnnotationSocket';
import { useSaveSettingsMutation } from 'services/slideSettings';
import { get } from 'lodash';
import RubberbandRectangleTool from 'components/DrawTool/Rectangle/RubberbandRectangleTool';
import { useCanvasEvent } from './useAnnotationEvent';
import {
  convertSvgToSelector,
  initSelectedAnno,
  unSelectedAnno,
  addStyleToAnnotation,
  removeStyleFromAnnotation,
  addStyleToAnnotationAndPointer
} from 'utils/shape.helper';
import { ZoomSlider } from 'components/ZoomSlider';
import {
  useCheckCapIdMutation,
  useGetBiomarkerByAnnotationIdMutation
} from 'services/biomarker';
import SlideRightPanel from '../SlideRightPanel/SlideRightPanel';
import { convertArray } from 'utils/convertArray';

export const AnnotationContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'EXPORT_ANNOTATIONS': {
      return {
        ...state,
        exportAnnotationCount: state.exportAnnotationCount + 1
      };
    }

    case 'ADD_ANNOTATIONS': {
      const newAnnotations = [...state.annotations];
      action.payload.forEach((newItem) => {
        const index = state.annotations.findIndex(
          (item) => item._id === newItem._id
        );
        if (index === -1) {
          newAnnotations.push(newItem);
        } else {
          const old = { ...newAnnotations[index] };
          newAnnotations[index] = { ...old, ...newItem };

          for (let i in newAnnotations) {
            if (newAnnotations[i].stroke === old.stroke) {
              newAnnotations[i] = {
                ...newAnnotations[i],
                stroke: newItem.stroke
              };
            }
          }
        }
      });

      return {
        ...state,
        annotations: [...newAnnotations]
      };
    }
    case 'UPDATE_ANNOTATION': {
      // update a single annotation item
      const newAnnotations = state.annotations.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload; // Replace the matched item with the payload
        } else if (
          action.payload._refreshGroup &&
          action.payload.group === item.group
        ) {
          return { ...item, stroke: action.payload.stroke }; // Update stroke for matching group
        }
        return item; // Return item unmodified if neither condition is met
      });
      return { ...state, annotations: [...newAnnotations] };
    }
    case 'DELETE_ANNOTATION': {
      const newAnnotations = [...state.annotations];
      const index = state.annotations.findIndex(
        (item) => item.id === action.payload
      );

      const deletedAnnotation = newAnnotations[index];
      if (index !== -1) {
        newAnnotations.splice(index, 1);
      }

      if (deletedAnnotation) {
        state.anno.removeAnnotation(deletedAnnotation.id);
      }

      return {
        ...state,
        annotations: [...newAnnotations]
      };
    }
    case 'SET_STATE': {
      return { ...state, [action.key]: action.value };
    }
    case 'UPDATE_GROUP_CHANGE': {
      return { ...state, [action.key]: state.selectedGroupChange + 1 };
    }
    case 'UPDATE_SELECTED_ANNO': {
      return { ...state, [action.key]: action.value };
    }
    case 'UPDATE_GROUP': {
      let currentStrokes = [];
      action.payload.forEach((newItem) => {
        currentStrokes.push(newItem['Color']);
      });
      const newAnnotations = state.annotations.filter((annotation) =>
        currentStrokes.includes(annotation.stroke)
      );
      return {
        ...state,
        annotations: [...newAnnotations],
        slide: {
          ...state.slide,
          groups: action.payload
        }
      };
    }
    case 'RELOAD_ANNOTATIONS_TAG': {
      const slideTagIds = state.slide.tags.map((tag) => {
        return tag.id.toString();
      });
      const newAnnotations = state.annotations
        ? state.annotations.map((item) => {
            const tags = (item.tags || []).filter((tag) => {
              if (typeof tag === 'object' && tag.id) {
                return slideTagIds.includes(tag.id.toString());
              }
              return slideTagIds.includes(tag.toString());
            });
            return {
              ...item,
              tags
            };
          })
        : [];
      return {
        ...state,
        annotations: [...newAnnotations]
      };
    }

    case 'RELOAD_ANNOTATIONS_CASE_IDENTIFIER': {
      const slideCaseIdentifiers = state.slide.caseIdentifiers.map((ci) => {
        return ci.id.toString();
      });
      const newAnnotations = state.annotations
        ? state.annotations.map((item) => {
            const caseIdentifiers = (item.caseIdentifiers || []).filter(
              (ci) => {
                if (typeof ci === 'object' && ci.id) {
                  return slideCaseIdentifiers.includes(ci.id.toString());
                }
                return slideCaseIdentifiers.includes(ci.toString());
              }
            );
            return {
              ...item,
              caseIdentifiers
            };
          })
        : [];
      return {
        ...state,
        annotations: [...newAnnotations]
      };
    }

    case 'RELOAD_BIOMARKERS': {
      return {
        ...state,
        biomarkers: action.payload
      };
    }

    default:
      return state;
  }
};

const ImageViewer = ({
  tileSources,
  slide,
  annotationsData,
  maxZoomLevel,
  setSelectAnnotation,
  selectAnnotation,
  refetchAnnotations
}) => {
  const inputAccept = '.xml';
  const analysisFileAccept = '.xlsx';
  const inputFileRef = useRef(null);
  const analysisFileRef = useRef(null);
  const tilesMapRef = useRef(new Map());
  const [searchParams, setSearchParams] = useSearchParams();
  const [saveSettings] = useSaveSettingsMutation();
  // const [checkCapId] = useCheckCapIdMutation();
  const organization = useSelector(selectOrganization);
  OpenSeaDragon.DEFAULT_SETTINGS.timeout = 600000;
  OpenSeaDragon.requestAnimationFrame = function (callback) {
    setTimeout(callback, 1);
  };

  const initialState = {
    slide: {},
    annotations: [],
    viewer: null,
    anno: null,
    annotationStyles: {},
    color: slide.settings?.color || '#000000',
    tool: '',
    selectedAnno: {},
    exportAnnotationCount: 0,
    selectedGroupChange: 0,
    uploadFile: null,
    isOverwrite: false,
    biomarkers: {}
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { emitAnnotationUpserted, emitAnnotationDeleted } = useAnnotationSocket(
    slide._id,
    dispatch,
    state.anno
  );
  const [deleteAnno, showAnnoDeleteAlert, hideAnnoDeleteAlert] =
    useToggleState(null);
  const [overwriteAnno, setOverwriteAnno] = useState(false);
  const [uploadAnnoConfirm, setUploadAnnoConfirm] = useState(false);
  const [analysisFile, setAnalysisFile] = useState(null);
  const [overwriteAnalysisFileAlert, setOverwriteAnalysisFileAlert] =
    useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadingAnnotations, setUploadingAnnotations] = useState(false);
  const [slideRightPanelOpen, setSlideRightPanelOpen] = useState(false);

  const {
    user: { role }
  } = useAuth();

  const currentColor = useRef(slide.settings?.color || '#000000');

  const [createAnnotation] = useCreateAnnotationMutation();
  const [editAnnotation] = useEditAnnotationMutation();
  const [deleteAnnotation] = useDeleteAnnotationMutation();
  const [exportAnnotations] = useExportAnnotationsMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [panMode, setPanMode] = useState(true);
  const [currentTool, setCurrentTool] = useState('');
  const [mulSelectMode, setMulSelectMode] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [getFileById] = useGetFileByIdMutation();
  const [currentAnnotation, setCurrentAnnotation] = useState(null);
  const [maxMinZoomlevel, setMaxMinZoomlevel] = useState({
    min: 0.5,
    max: 40
  });
  const [zoomLevel, setZoomLevel] = useState(1);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [openAnalysisGraphModal, setOpenAnalysisGraphModal] = useState(false);
  const [compareAnno, setCompareAnno] = useState({});
  const [
    openAnalysisGraphCompSelectionModal,
    setOpenAnalysisGraphCompSelectionModal
  ] = useState(false);
  const [openAnalysisGraphCompModal, setOpenAnalysisGraphCompModal] =
    useState(false);
  const [getBiomarkerByAnnotationId, { isLoading: isReloadBiomarkers }] =
    useGetBiomarkerByAnnotationIdMutation();

  const handleZoomChange = (newValue, isCenter) => {
    if (newValue > maxMinZoomlevel.max) {
      newValue = maxMinZoomlevel.max;
    }
    if (newValue < maxMinZoomlevel.min) {
      newValue = maxMinZoomlevel.min;
    }
    setZoomLevel(newValue);
    if (state.viewer) {
      if (isCenter) {
        const point = state.viewer.viewport.getBounds().getCenter();
        state.viewer.viewport.panTo(point);
        return;
      }
      state.viewer.viewport.zoomTo(newValue);
    }
  };

  const [isSelectedAnnotation, setIsSelectedAnnotation] = useState(false);

  const handleSwitchMulSelect = (value) => {
    setMulSelectMode(value);
  };

  const handleUpdateAnnotation = async (annotations) => {
    try {
      const isSingleAnnotation = annotations.length === 1;
      await Promise.all(
        annotations.map(async (anno) => {
          if (isSingleAnnotation) {
            const { title, tags, caseIdentifiers } =
              getCurrentAnnotationStorage();
            if (!title || title?.trim() === '') {
              const annotationParam = searchParams.get('annotation');
              if (annotationParam) {
                searchParams.delete('annotation');
                setSearchParams(searchParams.toString());
              }
              removeCurrentAnnotationStorage();
              throw new Error('Update annotation failed');
            }

            anno.title = title.trim();
            anno.tags = convertArray(tags);
            anno.caseIdentifiers = convertArray(caseIdentifiers);
          } else {
            anno.tags = getTagIdsFromTagObject(anno.tags);
            anno.caseIdentifiers = getCaseIdentifierIdsFromCaseIdentifierObject(
              anno.caseIdentifiers
            );
          }

          if (await handleUpdateSingleAnno(anno)) {
            enqueueSnackbar('Updated annotation', {
              variant: 'success'
            });
          } else {
            enqueueSnackbar('Update annotation failed', {
              variant: 'error'
            });
          }
        })
      );
    } catch (error) {
      console.log('error handleUpdateAnnotation:', error);
      enqueueSnackbar('Update annotation failed', {
        variant: 'error'
      });
    }
  };

  // const getTagsObjectWithIds = (tagIds) => {
  //   const tags = state.slide?.tags || [];
  //   return tagIds
  //     .map((tagId) => {
  //       const tag = tags.find((tag) => tag.id === +tagId);
  //       return tag ? tag : null;
  //     })
  //     .filter((tag) => tag !== null);
  // };

  // const getCaseIdentifierObjectWithIds = (ciIds) => {
  //   const cis = state.slide?.caseIdentifiers || [];
  //   return ciIds
  //     .map((ciId) => {
  //       const ci = cis.find((ci) => ci.id === +ciId);
  //       return ci ? ci : null;
  //     })
  //     .filter((ci) => ci !== null);
  // };

  const getTagIdsFromTagObject = (tags) => {
    return tags
      .map((tag) => {
        return tag?.id || null;
      })
      .filter((tag) => tag !== null);
  };

  const getCaseIdentifierIdsFromCaseIdentifierObject = (caseIdentifiers) => {
    return caseIdentifiers
      .map((caseIdentifier) => {
        return caseIdentifier?.id || null;
      })
      .filter((caseIdentifier) => caseIdentifier !== null);
  };

  const handleUpdateSingleAnno = async (annotation) => {
    if (annotation.shapeType === SHAPE_TYPES.rect) {
      annotation.target = {
        ...annotation.target,
        selector: convertSvgToSelector(annotation.target.selector)
      };
    }
    const { description, ...rest } = annotation;

    const shapeSelectorVal = get(rest, 'target.selector.value');
    if (shapeSelectorVal) {
      // remove inline svg styles
      const target = {
        ...rest.target,
        selector: {
          ...rest.target.selector,
          value: shapeSelectorVal.replace(/\sstyle=".+;"/, '')
        }
      };

      rest.target = target;
    }

    try {
      const response = await editAnnotation({
        ...rest,
        id: annotation._id,
        upload: slide._id
      });

      if (response?.data?.data) {
        dispatch({
          type: 'ADD_ANNOTATIONS',
          payload: [{ ...response.data.data }]
        });
        emitAnnotationUpserted(response.data.data);
        removeCurrentAnnotationStorage();
        updateGroup(slide._id);

        return true;
      }
    } catch (ex) {
      console.log('error handleUpdateSingleAnno:', ex);
    }
    return false;
  };

  const handleCreateAnnotation = async (
    anotationsList,
    overrideId,
    movedAnnotations
  ) => {
    handleOpen();
    const data = [];
    for (let i = 0; i < anotationsList.length; i++) {
      const annotation = anotationsList[i];
      const shapeType = SHAPE_TYPES[currentTool] || SHAPE_TYPES.Freehand;
      const {
        id,
        title,
        stroke,
        shapeType: currentType,
        ...restAnnotationFields
      } = annotation;

      const annoStorageData = getCurrentAnnotationStorage();

      const newAnnotation = {
        ...restAnnotationFields,
        slideId: slide._id,
        stroke: stroke ? stroke : state.color,
        shapeType: currentType ? currentType : shapeType,
        title: title ? title : annoStorageData.title,
        tags: convertArray(annoStorageData.tags),
        caseIdentifiers: convertArray(annoStorageData.caseIdentifiers)
      };

      if (newAnnotation.shapeType === SHAPE_TYPES.rect) {
        newAnnotation.target.selector = convertSvgToSelector(
          newAnnotation.target.selector
        );
      }

      const shapeSelectorVal = get(newAnnotation, 'target.selector.value');
      if (shapeSelectorVal) {
        // remove inline svg styles
        newAnnotation.target.selector.value = shapeSelectorVal.replace(
          /\sstyle=".+;"/,
          ''
        );
      }

      try {
        const { data: createdAnnotationData } = await createAnnotation({
          ...newAnnotation
        }).unwrap();
        if (createdAnnotationData && createdAnnotationData._id) {
          removeCurrentAnnotationStorage();
          const newAnnotationCreated = {
            ...createdAnnotationData,
            id: createdAnnotationData._id
          };
          emitAnnotationUpserted(newAnnotationCreated);
          await updateGroup(slide._id);
          dispatch({
            type: 'ADD_ANNOTATIONS',
            payload: [newAnnotationCreated]
          });
          data.push(newAnnotationCreated);
        } else {
          removeCurrentAnnotationStorage();
          state.anno.removeAnnotation(annotation.id);
        }
        enqueueSnackbar('Create annotation', {
          variant: 'success'
        });
      } catch (error) {
        console.log('error handleCreateAnnotation:', error);
        enqueueSnackbar('Create failed', {
          variant: 'error'
        });
      }
    }
    state.anno.setAnnotations([
      ...data,
      ...state.anno.getAnnotations().filter((ano) => !ano.parentCopy && ano._id)
    ]);
    if (movedAnnotations?.length) {
      setSelectAnnotation([...data]);
    }
    data.length &&
      navigate(
        `/slideViewer/${slide._id}?annotation=${data[data.length - 1]?._id}`
      );
    handleClose();
  };

  const onClickAnnoAnalysis = (annotations) => {
    console.log(annotations);
    setOpenAnalysisModal(true);
  };

  const onClickAnnoAnalysisGraph = (annotations) => {
    console.log(annotations);
    setOpenAnalysisGraphModal(true);
  };

  const onClickAnnoAnalysisGraphComp = (annotations) => {
    setOpenAnalysisGraphCompSelectionModal(true);
  };

  //event for copy/paste annotation
  useCanvasEvent(
    state?.viewer,
    selectAnnotation,
    state?.anno,
    mulSelectMode,
    handleSwitchMulSelect,
    setSelectAnnotation,
    isSelectedAnnotation,
    setIsSelectedAnnotation,
    currentAnnotation,
    setCurrentAnnotation,
    handleUpdateAnnotation,
    handleCreateAnnotation,
    showAnnoDeleteAlert
  );

  const handleClose = () => {
    setBackdrop(false);
  };
  const handleOpen = () => {
    setBackdrop(true);
  };

  function downLoadAnnotations() {
    const date = moment().format('DDMMMYY');
    exportAnnotations({
      projectName: slide.project.name,
      slideName: slide.name,
      ownerEmail: slide.createdBy.email
    }).then((response) => {
      if (response.data.success) {
        const blob = new Blob([JSON.stringify(response.data.data, null, 2)], {
          type: 'application/json'
        });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `EXPORT_${slide.project.name}_${slide.name}_${date}.json`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        dispatch({ type: 'EXPORT_ANNOTATIONS' });
      } else {
        enqueueSnackbar('Something wrong happened. Please try again', {
          variant: 'error'
        });
      }
    });
  }

  function uploadAnnotations() {
    inputFileRef.current.click();
  }

  const handleFileChange = (fileList) => {
    const files = [...Array(fileList.length).keys()].map((i) => fileList[i]);
    console.log(files[0]);

    files.map((file, index) => {
      return new Promise((resolve, reject) => {
        state.uploadFile = file;
        setOverwriteAnno(true);
      });
    });

    inputFileRef.current.value = null;
  };

  function uploadAnalysis() {
    analysisFileRef.current.click();
  }
  const uploadAnalysisFile = async (overwriteAnalysis) => {
    setUploadingFile(true);
    setAnalysisFile(null);
    setOverwriteAnalysisFileAlert(false);
    const headers = {
      'Content-Type': 'multipart/form-data' // A default header
    };

    if (organization) {
      headers['organization'] = organization._id; // Add the Authorization header conditionally
    }
    const file = analysisFile;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('slideId', slide._id);
    formData.append('slideName', slide.name);
    formData.append('projectName', slide.project.name);
    formData.append('overwriteAnalysis', overwriteAnalysis);

    axios
      .post('/annotations/uploadanalysis', formData, {
        headers: headers
      })
      .then(async (response) => {
        console.log('response:', response.data.data);
        await reloadBiomarkers();
        enqueueSnackbar('Analysis data uploaded successfully', {
          variant: 'success'
        });
        // TODO
        setUploadingFile(false);
      })
      .catch((error) => {
        console.log(error);
        setUploadingFile(false);
        enqueueSnackbar('Something wrong happened. Please try again', {
          variant: 'error'
        });
      });
  };

  const handleAnalysisFileChange = async (fileList) => {
    const files = [...Array(fileList.length).keys()].map((i) => fileList[i]);

    for (const file of files) {
      // const capId = file.name.split('.xlsx')[0];
      // const capIdExist = await checkCapId({ capId: capId });
      // if (capIdExist.data) {
      //   setAnalysisFile(file);
      //   setOverwriteAnalysisFileAlert(true);
      // } else {
      setAnalysisFile(file);
      setOverwriteAnalysisFileAlert(false);
      // }
    }
    analysisFileRef.current.value = null;
  };

  const annoFormatter = (anno) => {
    if (anno.underlying.id) {
      // in case of editing, apply the color of the annotation
      return anno.underlying.stroke
        ? {
            style: `stroke: ${anno.underlying.stroke};`
          }
        : '';
    }
    // in case of drawing, apply the selected color
    return {
      className: 'custom',
      style: `stroke: ${currentColor.current};fill:none !important;fillStyle:${currentColor.current}`
    };
  };

  const InitOpenseadragon = (slide) => {
    let maxZoomLevel = maxMinZoomlevel.max;
    if (slide && slide.lcmData && slide.lcmData.Scan) {
      maxZoomLevel = slide.lcmData.Scan.Objective || 10;
      setMaxMinZoomlevel({
        ...maxMinZoomlevel,
        max: maxZoomLevel
      });
    }
    state.viewer && state.viewer.destroy();
    tilesMapRef.current.clear();
    handleOpen();
    const initViewer = OpenSeaDragon({
      id: 'openSeaDragon',
      prefixUrl: '/openseadragon-images/',
      tileSources,
      showNavigator: true,
      navigatorPosition: 'BOTTOM_RIGHT',
      navigatorAutoFade:  false,
      // zoomInButton: 'zoom-in',
      // zoomOutButton: 'zoom-out',
      homeButton: 'home-button',
      constrainDuringPan: true,
      // SET MAX ZOOM ABLE TO BE 40x
      maxZoomLevel: maxZoomLevel,
      minZoomLevel: maxMinZoomlevel.min
    });
    initViewer.addHandler('resize', function () {
      setTimeout(function () {
        initViewer.drawer.context.imageSmoothingEnabled = false;
      }, 1);
    });

    initViewer.addHandler('open', function () {
      const tiledImage = initViewer.world.getItemAt(0);
      if (tiledImage.getFullyLoaded()) {
        handleClose();
      } else {
        tiledImage.addOnceHandler('fully-loaded-change', handleClose);
      }
    });

    dispatch({ type: 'SET_STATE', key: 'viewer', value: initViewer });

    const config = {
      color: currentColor.current,
      widgets: [
        AnnoFromWidget,
        {
          widget: AnnoInputWidget,
          id: 'currentAnnoTitle',
          name: 'title',
          label: 'Title',
          placeholder: 'Annotation title',
          autofocus: true,
          disabled: false,
          required: true,
          onEnterSubmit: true
        },
        {
          slideId: slide._id,
          widget: AnnoAutocompleteWidget,
          id: 'currentAnnoTags',
          label: 'Tags',
          name: 'tags',
          placeholder: 'Annotation tags',
          autofocus: false,
          disabled: false,
          required: false,
          hasCheckbox: false
        },
        {
          slideId: slide._id,
          widget: AnnoAutocompleteWidget,
          id: 'currentAnnoCaseIdentifiers',
          name: 'caseIdentifiers',
          label: 'Case Identifiers',
          placeholder: 'Case Identifiers',
          autofocus: false,
          disabled: false,
          required: false,
          hasCheckbox: false
        },
        {
          widget: AnnoAnalysisBtnWidget,
          onClick: onClickAnnoAnalysis
        },
        {
          widget: AnalysisGraphBtnWidget,
          onClick: onClickAnnoAnalysisGraph
        },
        {
          widget: AnalysisGraphCompBtnWidget,
          onClick: onClickAnnoAnalysisGraphComp
        }
      ],
      readOnly: false,
      allowEmpty: true,
      drawOnSingleClick: true,
      formatters: [annoFormatter]
    };
    const annotate = Annotorious(initViewer, config);
    annotate.addDrawingTool(RubberbandRectangleTool);
    annotate.addDrawingTool(RubberbandFreehandTool);
    annotate.addDrawingTool(RubberbandEllipseTool);
    dispatch({ type: 'SET_STATE', key: 'anno', value: annotate });
  };

  const switchToPanMode = () => {
    if (state.anno) {
      handleSetTool('');
      state.anno.setDrawingEnabled(false);
    }
    setMulSelectMode(false);
    setPanMode(true);
  };

  const handleNavigateToSlidebox = () => {
    navigate('/slidebox');
  };

  const handleColorChange = async (color) => {
    dispatch({ type: 'SET_STATE', key: 'color', value: color });
    currentColor.current = color;
    localStorage.setItem('color', JSON.stringify(color));
    await saveSettings({ slide: slide._id, color }).unwrap();
  };

  const handleSetTool = (value) => {
    if (value) {
      setPanMode(false);
      handleSwitchMulSelect(false);
      setCurrentTool(value);
      state.anno.setDrawingEnabled(true);
    }
    dispatch({ type: 'SET_STATE', key: 'tool', value: value });
  };

  const updateGroup = async (id) => {
    const { data } = await getFileById(id).unwrap();
    dispatch({
      type: 'UPDATE_GROUP',
      payload: data.groups
    });
  };

  const initSlide = async (slide) => {
    const { data } = await getFileById(slide._id).unwrap();
    dispatch({ type: 'SET_STATE', key: 'slide', value: data ?? slide });
  };

  useEffect(() => {
    // Watch for changes to the 'file' state
    if (analysisFile !== null && overwriteAnalysisFileAlert === false) {
      uploadAnalysisFile(false);
    }
  }, [analysisFile, overwriteAnalysisFileAlert]);

  /* Run when init component */
  useEffect(() => {
    initSlide(slide);
    InitOpenseadragon(slide);
    return () => {
      state.viewer && state.viewer.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide, annotationsData]);

  useEffect(() => {
    selectAnnotationsOfGroup();
    handleColorChange(state.color);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedGroupChange]);

  useEffect(() => {
    if (state.selectedAnno.id) {
      handleColorChange(state.selectedAnno.stroke);
      // state.anno.setDrawingTool(
      //   state.selectedAnno.shapeType === 'rectangle'
      //     ? 'rect'
      //     : state.selectedAnno.shapeType
      // );
      // handleSetTool(
      //   state.selectedAnno.shapeType === 'rectangle'
      //     ? 'rect'
      //     : state.selectedAnno.shapeType
      // );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedAnno.id]);

  useEffect(() => {
    if (state?.anno) {
      initSelectedAnno(selectAnnotation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.anno?.getAnnotations(), selectAnnotation, state.viewer]);

  /* Init Annotation tools */
  useEffect(() => {
    if (state.anno && annotationsData) {
      state.anno.setAnnotations(annotationsData);
      dispatch({ type: 'ADD_ANNOTATIONS', payload: annotationsData });
    }

    return () => {
      state.viewer && state.viewer.destroy();
    };
  }, [state.anno, annotationsData, state.viewer]);

  useEffect(() => {
    if (state.anno) {
      state.anno.off('createAnnotation');
      state.anno.on('createAnnotation', async (annotation, overrideId) => {
        dispatch({
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: {}
        });
        const { title } = getCurrentAnnotationStorage();
        if (!title || title?.trim() === '') {
          state.anno.removeAnnotation(annotation.id);
          const annotationParam = searchParams.get('annotation');
          if (annotationParam) {
            searchParams.delete('annotation');
            setSearchParams(searchParams.toString());
          }
          enqueueSnackbar('Create failed, Annotation Title is required', {
            variant: 'error'
          });
          removeCurrentAnnotationStorage();
          return;
        }
        await handleCreateAnnotation([annotation], overrideId);
        state.anno.setDrawingEnabled(true);
      });

      state.anno.on('deleteAnnotation', (annotation) => {
        const prevAnnotation = state.anno.getAnnotations();
        state.anno.setAnnotations([...prevAnnotation, annotation]);
        state.anno.selectAnnotation(annotation);
        if (annotation.id) {
          showAnnoDeleteAlert([annotation]);
        }
        setPanMode(true);
        handleSetTool('');
      });

      state.anno.off('updateAnnotation');
      state.anno.on('updateAnnotation', async (annotation, previous) => {
        dispatch({
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: {}
        });
        if (annotation.id) {
          await handleUpdateAnnotation([annotation]);
        }
        setPanMode(true);
        handleSetTool('');
      });

      return () => {
        state.anno.off('deleteAnnotation');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.anno,
    slide._id,
    state.color,
    state.tool,
    selectAnnotation,
    currentAnnotation
  ]);

  //change cursor when click ctrl
  useEffect(() => {
    if (mulSelectMode) {
      const elements = document.querySelectorAll('.a9s-annotation');
      elements.forEach((el) => {
        const childElements = el.children;
        if (childElements.length && childElements[1]) {
          childElements[1].style.cursor = 'copy';
        }
      });
    } else {
      const elements = document.querySelectorAll('.a9s-annotation');
      elements.forEach((el) => {
        const childElements = el.children;
        if (childElements.length && childElements[1]) {
          childElements[1].style.cursor = 'pointer';
        }
      });
    }
  }, [mulSelectMode]);

  /**
   * Binding event handler to sync URL search param with selected annotation
   */
  useEffect(() => {
    if (state.anno) {
      state.anno.on('mouseEnterAnnotation', function (annotation) {
        state.anno.setDrawingEnabled(false);
        const element = document.querySelector('.a9s-annotation.hover');
        const childElements = element && element.children;
        if (childElements && childElements.length > 1) {
          addStyleToAnnotation(childElements[1], annotation);
        }
        if (selectAnnotation.length && !mulSelectMode) {
          if (isSelectedAnnotation) return;
          const findAnno = selectAnnotation.find(
            (v) => v?._id === annotation._id
          );
          if (findAnno?._id) {
            setCurrentAnnotation(annotation);
            setIsSelectedAnnotation(true);
            return;
          } else {
            setCurrentAnnotation(null);
            setIsSelectedAnnotation(false);
          }
        }
      });
      state.anno.on('mouseLeaveAnnotation', function (annotation, element) {
        if (state.tool) {
          state.anno.setDrawingEnabled(true);
        }
        const childElements = element.children;
        if (childElements && childElements.length > 1) {
          removeStyleFromAnnotation(childElements[1], annotation);
        }
        setIsSelectedAnnotation(false);
      });
      state.anno.on('selectAnnotation', function (data, element) {
        dispatch({
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: data
        });
        setCurrentAnnotationStorage(data);
        const childElements = element.children;
        if (childElements && childElements.length > 0) {
          addStyleToAnnotationAndPointer(childElements, data);
        }

        if (mulSelectMode) {
          const allAnotations = state.anno
            .getAnnotations()
            .map((currentAnno) => {
              const shapeSelector = get(currentAnno, 'target.selector');
              if (
                currentAnno.shapeType === SHAPE_TYPES.rect &&
                shapeSelector.type === 'SvgSelector'
              ) {
                currentAnno = {
                  ...currentAnno,
                  target: {
                    ...currentAnno.target,
                    selector: convertSvgToSelector(shapeSelector)
                  }
                };
                return currentAnno;
              }
              return currentAnno;
            });
          const cloneData = allAnotations.find((v) => v._id === data._id);
          const exitedAnno = selectAnnotation.find(
            (v) => v._id === cloneData._id
          );
          let newSelectedListAnno = [...selectAnnotation];
          if (exitedAnno?._id) {
            newSelectedListAnno = selectAnnotation.filter(
              (v) => v._id !== cloneData._id
            );
          } else {
            newSelectedListAnno = [...newSelectedListAnno, cloneData];
          }
          setSelectAnnotation(newSelectedListAnno);
          state.anno.setAnnotations(allAnotations);
        } else {
          unSelectedAnno(selectAnnotation);
          setSelectAnnotation([]);
          const annotationParam = searchParams.get('annotation');
          if (annotationParam !== data._id) {
            searchParams.set('annotation', data._id);
            setSearchParams(searchParams.toString());
          }
        }
      });

      state.anno.on('cancelSelected', function () {
        dispatch({
          type: 'UPDATE_SELECTED_ANNO',
          key: 'selectedAnno',
          value: {}
        });
        const annotationParam = searchParams.get('annotation');
        removeCurrentAnnotationStorage();
        if (annotationParam) {
          searchParams.delete('annotation');
          setSearchParams(searchParams.toString());
        }
        // if (state.tool) {
        setPanMode(true);
        handleSetTool('');
        // }
      });
      return () => {
        state.anno.off('selectAnnotation');
        state.anno.off('cancelSelected');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.anno,
    searchParams,
    setSearchParams,
    state.tool,
    mulSelectMode,
    selectAnnotation,
    isSelectedAnnotation,
    currentAnnotation
  ]);

  const selectAnnotationsOfGroup = () => {
    const tempAnno = [];
    if (state.anno) {
      state.annotations.forEach((annotation) => {
        if (annotation.stroke === state.color) {
          tempAnno.push(annotation);
        }
      });
      handleSetTool('');
      setPanMode(true);
      setSelectAnnotation([]);
      setSelectAnnotation(tempAnno);
    }
  };
  useEffect(() => {
    if (state.anno) {
      state.anno.setAnnotations(state.annotations);
    }
  }, [state.color, state.annotations, state.slide.groups, state.anno]);

  useEffect(() => {
    if (state.anno) {
      state.anno.on('createSelection', function (selection) {
        const element = document.querySelector(
          '.a9s-annotation.editable.selected'
        );
        const childElements = element.children;
        if (childElements && childElements.length > 0) {
          addStyleToAnnotationAndPointer(childElements, {
            stroke: state.color
          });
        }
      });
    }
  });

  useEffect(() => {
    removeCurrentAnnotationStorage();
  }, []);

  // update optionsTags for Widget
  useEffect(() => {
    if (state.slide?.tags && state.anno) {
      const options = state.slide?.tags.map((v) => ({
        label: v.name,
        value: v.id
      }));
      localStorage.setItem(
        `widget-options-tags-${state?.slide?._id}`,
        JSON.stringify(options)
      );
      // close anno select
      removeCurrentAnnotationStorage();
      handleClose();
      state.anno.selectAnnotation();
      setSelectAnnotation([]);
      dispatch({ type: 'RELOAD_ANNOTATIONS_TAG' });
      updateGroup(slide._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide?.tags, state.anno]);

  // update optionsCaseIdentifiers for Widget
  useEffect(() => {
    if (state.slide?.caseIdentifiers && state.anno) {
      const options = state.slide?.caseIdentifiers.map((v) => ({
        label: `Patient Id: ${v.patientId}`,
        value: v.id
      }));
      localStorage.setItem(
        `widget-options-caseIdentifiers-${state?.slide?._id}`,
        JSON.stringify(options)
      );
      // close anno select
      removeCurrentAnnotationStorage();
      handleClose();
      state.anno.selectAnnotation();
      setSelectAnnotation([]);
      dispatch({ type: 'RELOAD_ANNOTATIONS_CASE_IDENTIFIER' });
      updateGroup(slide._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.slide?.caseIdentifiers, state.anno]);

  const handleDeleteAnnotation = async () => {
    if (deleteAnno.length) {
      hideAnnoDeleteAlert();
      handleOpen();
      for (const anno of deleteAnno) {
        await handleDeleteSingleAnoo(anno);
      }
      enqueueSnackbar('Deleted annotation successfully', {
        variant: 'success'
      });
      removeCurrentAnnotationStorage();
      handleClose();
      state.anno.selectAnnotation();
      dispatch({ type: 'SET_STATE', key: 'selectedAnno', value: {} });
      setSelectAnnotation([]);
    }
  };

  const handleDeleteSingleAnoo = async (anno) => {
    if (!anno?._id) return;
    try {
      await deleteAnnotation(anno._id);
      dispatch({
        type: 'DELETE_ANNOTATION',
        payload: anno._id
      });
      emitAnnotationDeleted({ slide, _id: anno._id });
      updateGroup(slide._id);
    } catch (e) {
      enqueueSnackbar(e.toString(), {
        variant: 'error'
      });
    }
  };

  const cancelDeleteAnnotation = () => {
    const prevAnnotation = state.anno.getAnnotations();
    const checkExitedAnno = prevAnnotation.find(
      (v) => v._id === deleteAnno[0]._id
    );
    if (!checkExitedAnno?._id) {
      state.anno.setAnnotations([...prevAnnotation, ...deleteAnno]);
    }
    hideAnnoDeleteAlert();
  };

  const handleOverwriteAnnotation = () => {
    state.isOverwrite = true;
    postAnnotationsData();
    setOverwriteAnno(false);
    state.uploadFile = null;
  };

  const cancelOverwriteAnnotation = () => {
    state.isOverwrite = false;
    postAnnotationsData();
    setOverwriteAnno(false);
    state.uploadFile = null;
  };

  const postAnnotationsData = () => {
    const formData = new FormData();
    formData.append('file', state.uploadFile);
    formData.append('slideName', slide.name);
    formData.append('isOverwrite', state.isOverwrite);
    formData.append('slideId', slide._id);

    const headers = {
      'Content-Type': 'multipart/form-data' // A default header
    };

    if (organization) {
      headers['organization'] = organization._id; // Add the Authorization header conditionally
    }
    setUploadingAnnotations(true);

    axios
      .post('/annotations/uploadxml', formData, {
        headers: headers
      })
      .then(async (response) => {
        console.log('response:', response.data.data);
        dispatch({ type: 'SET_STATE', key: 'annotations', value: [] });
        refetchAnnotations && (await refetchAnnotations());
        setUploadingAnnotations(false);
        enqueueSnackbar('Annotation data uploaded successfully', {
          variant: 'success'
        });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Something wrong happened. Please try again', {
          variant: 'error'
        });
      });
  };

  const hideUploadAnnoConfirm = () => {
    setUploadAnnoConfirm(false);
  };

  const setCurrentAnnotationStorage = ({ title, tags, caseIdentifiers }) => {
    localStorage.setItem('currentAnnoTitle', title);
    localStorage.setItem(
      'currentAnnoTags',
      getTagIdsFromTagObject(tags).join()
    );
    localStorage.setItem(
      'currentAnnoCaseIdentifiers',
      getCaseIdentifierIdsFromCaseIdentifierObject(caseIdentifiers).join()
    );
  };
  const removeCurrentAnnotationStorage = () => {
    localStorage.removeItem('currentAnnoTitle');
    localStorage.removeItem('currentAnnoTags');
    localStorage.removeItem('currentAnnoCaseIdentifiers');
  };
  const getCurrentAnnotationStorage = () => {
    const title = localStorage.getItem('currentAnnoTitle') || '';
    const tags = localStorage.getItem('currentAnnoTags') || [];
    const caseIdentifiers =
      localStorage.getItem('currentAnnoCaseIdentifiers') || [];
    return { title, tags, caseIdentifiers };
  };
  const compareWithChange = (group) => {
    let annoFromGroup;
    state.annotations.forEach((anno) => {
      if (anno.stroke === group.Color) {
        annoFromGroup = anno;
        return;
      }
    });
    setCompareAnno(annoFromGroup);
    setOpenAnalysisGraphCompSelectionModal(false);
    setOpenAnalysisGraphCompModal(true);
  };

  const reloadBiomarkers = async () => {
    try {
      const listAnnoId = state.annotations.map((anno) => anno._id);
      const biomarkers = await Promise.all(
        listAnnoId.map(async (annoId) => {
          const data = await getBiomarkerByAnnotationId(annoId).unwrap();
          return { [annoId]: data };
        })
      );

      const biomarkersObj = biomarkers?.reduce((acc, cur) => {
        return { ...acc, ...cur };
      }, {});
      dispatch({ type: 'RELOAD_BIOMARKERS', payload: biomarkersObj });
    } catch (_) {}
  };

  const listAnnoIdS = state.annotations.map((anno) => anno._id).join(',');
  useEffect(() => {
    reloadBiomarkers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listAnnoIdS]);

  const handleRightPanelOpen = () => {
    setSlideRightPanelOpen(true);
  };

  const handleRightPanelClose = () => {
    setSlideRightPanelOpen(false);
  };

  useEffect(() => {
    if (state.selectedAnno.id) {
      handleRightPanelOpen();
    } else {
      // handleRightPanelClose();
    }
  }, [state.selectedAnno]);

  return (
    <SlideViewerContext.Provider value={[state, dispatch]}>
      <Box
        sx={{
          flexGrow: '1',
          marginTop: '0px',
          maxHeight: 'calc(100vh - 66px)',
          marginLeft: '0px',
          display: 'flex'
        }}
      >
        <SlideManager slideId={slide._id} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 8px 8px 8px',
            flexGrow: '1'
          }}
        >
          {/* TODO: command for now
        <DescSec>
          <Stack gap={4} direction="row" p={2}>
          <Description label="Filename">{uploadData.name}</Description>
          <Description label="File Size">
            {getSize(uploadData.size)}
          </Description>
          <Description label="Upload Date">
            {getLocaleDate(uploadData.createdAt)}
          </Description>
        </Stack>
      </DescSec> */}
          <DescSec
            sx={{
              backgroundColor: '#f8f8f8',
              border: 'none',
              mb: 0,
              pb: 1
            }}
          >
            <Box
              sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}
            >
              <ToolButton
                id="pan-to"
                onClick={switchToPanMode}
                ariaLabel="Pan Mode"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
                className={`tool-btn ${panMode ? 'active' : ''}`}
              >
                <OpenWithIcon />
              </ToolButton>
              <ToolButton
                onClick={() => handleZoomChange(1, true)}
                id="home-button"
                ariaLabel="Focus back"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
              >
                <CenterFocusWeakIcon />
              </ToolButton>

              <ToolButton
                onClick={() => handleZoomChange(zoomLevel + 1)}
                id="zoom-in"
                ariaLabel="Zoom In"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
              >
                <ZoomIn />
              </ToolButton>
              <ToolButton
                onClick={() => handleZoomChange(zoomLevel - 1)}
                id="zoom-out"
                ariaLabel="Zoom Out"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
              >
                <ZoomOut />
              </ToolButton>
              <DrawToolbar
                anno={state.anno}
                context={[state.tool, handleSetTool]}
              />
              <MuiColorInput
                className="custom-color-picker"
                sx={{ margin: '4px', padding: '7px' }}
                value={state.color}
                onChange={handleColorChange}
                format="hex8"
              />
              <ToolButton
                ariaLabel="Export Slide Data"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
                // disabled={!state.annotations.length}
                onClick={downLoadAnnotations}
              >
                <SystemUpdateAltIcon />
              </ToolButton>
              {!open && (
                <IconButton
                  disableRipple
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <InfoOutlined fontSize="inherit" color="info" />
                </IconButton>
              )}

              <ToolButton
                ariaLabel="Upload annotations"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
                // disabled={!state.annotations.length}
                onClick={uploadAnnotations}
              >
                <UploadIcon />
                <input
                  id="file-upload"
                  type="file"
                  accept={inputAccept}
                  style={{ display: 'none' }}
                  ref={inputFileRef}
                  onChange={(e) => handleFileChange(e.target.files)}
                />
              </ToolButton>

              <ToolButton
                ariaLabel="Upload analysis file"
                sx={{ display: 'flex!important', borderRadius: '0px' }}
                // disabled={!state.annotations.length}
                onClick={uploadAnalysis}
              >
                <ShowChartIcon />
                <input
                  id="analysis-file-upload"
                  type="file"
                  accept={analysisFileAccept}
                  style={{ display: 'none' }}
                  ref={analysisFileRef}
                  onChange={(e) => handleAnalysisFileChange(e.target.files)}
                />
              </ToolButton>

              <ZoomSlider
                zoomLevel={zoomLevel}
                min={maxMinZoomlevel.min}
                max={maxMinZoomlevel.max}
                handleZoomChange={handleZoomChange}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexGrow: 1,
                padding: '0px 8px',
                gap: 1
              }}
            >
              <Button
                startIcon={<ArrowBack />}
                variant="outlined"
                onClick={handleNavigateToSlidebox}
              >
                Back
              </Button>
              <MoveButton entity={slide} />
              <CopyButton entity={slide} />
              <ShareButton entities={[slide]} calledFrom="slideViewer" />
              {/* <InviteButton
                slideId={slide._id}
                createdBy={slide.createdBy?._id}
              /> */}
            </Box>
          </DescSec>
          <Box
            sx={{
              height: '100%',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              ...state.annotationStyles,
              cursor: panMode ? 'move' : 'crosshair',
              position: 'relative',
              zIndex: 2,
              overflow: 'hidden'
            }}
            id="openSeaDragon"
          />
        </Box>
        <SlideRightPanel
          slideId={slide._id}
          open={slideRightPanelOpen}
          selectedAnnotation={state.selectedAnno}
          handleToggle={(isOpen) => setSlideRightPanelOpen(isOpen)}
        />
      </Box>
      <Backdrop sx={{ color: '#fff', zIndex: 1202 }} open={backdrop}>
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: '16px' }}>
            Loading Slide...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: 1202 }} open={uploadingFile}>
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: '16px' }}>
            Uploading analysis file
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: '#fff', zIndex: 1202 }}
        open={uploadingAnnotations}
      >
        <Box
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          flexDirection="column"
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ marginTop: '16px' }}>
            Uploading annotations
          </Typography>
        </Box>
      </Backdrop>
      <Alert
        open={deleteAnno}
        title="Delete annotation"
        content="Are you sure to delete this annotation?"
        onDismiss={cancelDeleteAnnotation}
        onConfirm={handleDeleteAnnotation}
      />
      <Alert
        open={overwriteAnno}
        title="Overwrite annotations"
        content="Are you sure want to overwrite existing annotations?"
        onDismiss={cancelOverwriteAnnotation}
        onConfirm={handleOverwriteAnnotation}
        confirmBtn="Yes"
        cancelBtn="No"
      />
      <InfoAlert
        open={uploadAnnoConfirm}
        title="Import Annotation"
        content="Annotations are imported successfully!"
        onConfirm={hideUploadAnnoConfirm}
      />
      <Dialog
        open={openAnalysisModal}
        onClose={() => setOpenAnalysisModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <AnalysisPopUp
            biomarkers={
              state.biomarkers?.[state?.selectedAnno?._id] || undefined
            }
            isFetching={isReloadBiomarkers}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisModal(false)}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnalysisGraphModal}
        onClose={() => setOpenAnalysisGraphModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <AnnotationContext.Provider value={state?.selectedAnno?.stroke}>
            <AnalysisGraphPopUp
              biomarkers={
                state.biomarkers?.[state?.selectedAnno?._id] || undefined
              }
              isFetching={isReloadBiomarkers}
            />
          </AnnotationContext.Provider>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisGraphModal(false)}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnalysisGraphCompSelectionModal}
        onClose={() => setOpenAnalysisGraphCompSelectionModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <Autocomplete
            disableCloseOnSelect
            id="tags-outlined"
            size="small"
            options={state.slide.groups || []}
            onChange={(event, newValue) => {
              compareWithChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => option.Name === value.Name}
            getOptionLabel={(option) => {
              return option.Name;
            }}
            renderOption={(props, option) => {
              if (state?.selectedAnno?.stroke !== option.Color) {
                return <li {...props}>{option.Name}</li>;
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& label.MuiInputLabel-shrink': {
                    color: '#4A4A4A',
                    fontWeight: '600'
                  }
                }}
                label="Compare with"
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisGraphCompSelectionModal(false)}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAnalysisGraphCompModal}
        onClose={() => setOpenAnalysisGraphCompModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent className="custom-dialog-content">
          <AnnotationContext.Provider value={state?.selectedAnno?.stroke}>
            <AnalysisGraphPopUpWithCompare
              biomarkers={
                state.biomarkers?.[state?.selectedAnno?._id] || undefined
              }
              compBiomarkers={state.biomarkers?.[compareAnno?._id] || undefined}
              isFetching={isReloadBiomarkers}
              compareAnnoStroke={compareAnno?.stroke}
            />
          </AnnotationContext.Provider>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="buttonLightGray"
            disableElevation
            onClick={() => setOpenAnalysisGraphCompModal(false)}
          >
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={overwriteAnalysisFileAlert}
        title="Overwrite/Append Analysis Data"
        content="Are you sure you want to overwrite/append to the existing analysis Data?"
        onDismiss={() => {
          uploadAnalysisFile(false);
        }}
        onConfirm={() => {
          uploadAnalysisFile(true);
        }}
        confirmBtn="yes"
        cancelBtn="no"
      />
    </SlideViewerContext.Provider>
  );
};
export default ImageViewer;
